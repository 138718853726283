import React, { useState } from 'react';
import {
    Box,
    Button,
    Card,
    Checkbox,
    Flex,
    TextArea,
} from '@fluentui/react-northstar';
import { ClipboardCopiedToIcon } from '@fluentui/react-icons-northstar';
import { useUpdateAssignmentsMutation } from '../generated/graphql';
import copy from 'copy-text-to-clipboard';

interface Assignment {
    assessment?: boolean;
    assignment: string;
    courseIdentifier: string;
    cycle: string;
    day: string;
    fileName: string;
    live?: boolean;
    school?: string;
    view?: boolean;
}

const AssignmentPortal: React.FC<{ assignment: Assignment }> = ({
    assignment,
}) => {
    const [
        updateAssignment,
        { loading: updating },
    ] = useUpdateAssignmentsMutation();
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [state, setState] = useState<Assignment>(assignment);

    const handleChange = (name, val) => {
        setIsDirty(true);
        setState({ ...state, [name]: val });
    };
    const submitChanges = async () => {
        if (!isDirty) {
            return;
        }

        const resp = await updateAssignment({
            variables: {
                data: [
                    {
                        courseIdentifier: state.courseIdentifier,
                        fileName: state.fileName,
                        values: [
                            {
                                assignment: state.assignment,
                                day: state.day,
                                assessment: state.assessment,
                                live: state.live,
                                view: state.view,
                            },
                        ],
                    },
                ],
            },
        });

        console.log('updateAssignment resp: ', resp);
        setIsDirty(false);
    };

    return (
        <Card elevated styles={{ position: 'relative', overflow: 'hidden' }}>
            <form name="assignmentForm" onBlur={submitChanges}>
                <Card.Body>
                    <TextArea
                        name="assignment"
                        placeholder={`Assignment for ${state.courseIdentifier}...`}
                        value={state.assignment}
                        onChange={(e: any) =>
                            handleChange('assignment', e.target.value)
                        }
                    />

                    <Flex space="between">
                        <Checkbox
                            label="Assessment"
                            checked={state.assessment}
                            onChange={(e: any) =>
                                handleChange('assessment', e.target.checked)
                            }
                        />

                        <Checkbox
                            label="View"
                            checked={state.view}
                            onChange={(e: any) =>
                                handleChange('view', e.target.checked)
                            }
                        />
                    </Flex>

                    <Button
                        type="button"
                        icon={<ClipboardCopiedToIcon />}
                        circular
                        styles={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                        }}
                        onClick={() => copy(state.assignment)}
                    />
                </Card.Body>
            </form>

            <Box
                styles={{
                    display: updating ? 'block' : 'none',
                    position: 'absolute',
                    width: '100%',
                    height: '100px',
                    backgroundColor: 'red',
                }}
            >
                Loading
            </Box>
        </Card>
    );
};

export default React.memo(AssignmentPortal);
