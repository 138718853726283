export function parseExpression(expression: string) {
    let exps = expression.split(' ');
    const regex = /\(([^)]+)\)/;

    expression = exps
        .map((x: string) => {
            const matched = regex.exec(x);
            if (!matched) {
                return null;
            }

            const second = matched[1];
            let allDays: any[] = [];

            //if the second regex match does not include hyphen
            if (!second.includes('-')) {
                return x;
            }

            //if the match includes a hyphen and comma
            if (second.includes(',')) {
                const secondSplit = second.split(',');

                const withHyphens = secondSplit
                    .filter((s) => s.includes('-'))
                    .map(parseWithHyphens);

                const withoutHyphens = secondSplit.filter(
                    (s) => !s.includes('-')
                );

                allDays = withoutHyphens.concat(...withHyphens);
                allDays.sort();
            } else {
                allDays = allDays.concat(...parseWithHyphens(second));
            }

            x = x.replace(second, allDays.join(','));

            return x;
        })
        .join(' ');

    return expression;
}

function parseWithHyphens(withHyphen: string): string[] {
    const days = ['A', 'B', 'C', 'D', 'E', 'F'];

    const [start, end] = withHyphen.split('-');
    const startIdx = days.findIndex((d: string) => d === start);
    const endIdx = days.findIndex((d: string) => d === end);

    const hyphenDays = days.slice(startIdx, endIdx + 1);

    return hyphenDays;
}
