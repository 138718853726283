//@ts-nocheck
// interface ISchedule {
//   [key as any]?: {
//     start: string;
//     end: string;
//   };
// }
export const SCHEDULE = {
  P01: {
    start: '08:00:00',
    end: '08:38:00',
  },
  P02: {
    start: '08:42:00',
    end: '09:20:00',
  },
  P03: {
    start: '09:50:00',
    end: '10:28:00',
  },
  P04: {
    start: '10:32:00',
    end: '11:10:00',
  },
  P05: {
    start: '11:14:00',
    end: '11:52:00',
  },
  P06: {
    start: '11:56:00',
    end: '12:34:00',
  },
  P07: {
    start: '12:38:00',
    end: '13:16:00',
  },
  P08: {
    start: '13:20:00',
    end: '13:58:00',
  },
  P09: {
    start: '14:02:00',
    end: '14:40:00',
  },
  P10: {
    start: '14:44:00',
    end: '15:20:00',
  },
  P11: {
    start: '15:20:00',
    end: '15:30:00',
  },
  P12: {
    start: '15:30:00',
    end: '16:30:00',
  },
  P13: {
    start: '16:30:00',
    end: '17:30:00',
  },
  1: {
    start: '08:00:00',
    end: '08:38:00',
  },
  2: {
    start: '08:42:00',
    end: '09:20:00',
  },
  3: {
    start: '09:50:00',
    end: '10:28:00',
  },
  4: {
    start: '10:32:00',
    end: '11:10:00',
  },
  5: {
    start: '11:14:00',
    end: '11:52:00',
  },
  6: {
    start: '11:56:00',
    end: '12:34:00',
  },
  7: {
    start: '12:38:00',
    end: '13:16:00',
  },
  8: {
    start: '13:20:00',
    end: '13:58:00',
  },
  9: {
    start: '14:02:00',
    end: '14:40:00',
  },
  10: {
    start: '14:44:00',
    end: '15:20:00',
  },
  11: {
    start: '15:20:00',
    end: '15:30:00',
  },
  12: {
    start: '15:30:00',
    end: '16:30:00',
  },
  13: {
    start: '16:30:00',
    end: '17:30:00',
  },
};
