import React, { useEffect, useState } from 'react';
import { Flex, Dropdown, DropdownProps } from '@fluentui/react-northstar';
import {
    ChevronStartIcon,
    ChevronEndMediumIcon,
} from '@fluentui/react-northstar';
import { formatDate } from '../utils/formatDate';
import { useCycle } from '../utils/hooks/useCycle';
import { Calendar, useCycleLazyQuery } from '../generated/graphql';

interface CycleToggleProps {
    setSelectedCycle: (cycle: Partial<Calendar>[]) => void;
}

export const CycleToggle: React.FC<CycleToggleProps> = ({
    setSelectedCycle,
}) => {
    const { cycles, cycle } = useCycle();
    const [currentIdx, setCurrentIdx] = useState(0);
    const [loadCycle, { data: loadedCycle }] = useCycleLazyQuery();

    useEffect(() => {
        if (cycles.length && cycle.length) {
            // const curr = formatDate(cycle[0].date);
            const idx = cycles.findIndex((c) => c.date === cycle[0].date);
            setCurrentIdx(idx);
            setSelectedCycle(cycle);
        }
    }, [cycles, cycle, setCurrentIdx, setSelectedCycle]);

    useEffect(() => {
        if (loadedCycle) {
            setSelectedCycle(loadedCycle.cycle);
        }
    }, [loadedCycle, setSelectedCycle]);

    if (!cycle.length) {
        return null;
    }

    const handleChange = (_, d: DropdownProps) => {
        if (!d.highlightedIndex) {
            return;
        }

        setCurrentIdx(d.highlightedIndex);
        loadCycle({
            variables: {
                date: cycles[d.highlightedIndex].date,
            },
        });
    };

    return (
        <Flex vAlign="center" gap="gap.small">
            <ChevronStartIcon />

            <Dropdown
                items={cycles.map((c) => formatDate(c.date))}
                value={formatDate(cycles[currentIdx].date)}
                onChange={handleChange}
            />

            <ChevronEndMediumIcon />
        </Flex>
    );
};
