import React, { useState } from 'react';
import { Box, Button, Portal, Text, TextArea } from '@fluentui/react-northstar';
import { useMessageCoursesQuery } from '../generated/graphql';

interface Props {
    email: string;
}

const MessagePortal: React.FC<Props> = ({ email }) => {
    const { data, loading } = useMessageCoursesQuery({ variables: { email } });
    const [open, setOpen] = useState(false);

    const openPortal = () => {
        setOpen(true);
    };
    const closePortal = () => {
        setOpen(false);
    };

    if (loading || !data) {
        return <div />;
    }

    const btnContent = open ? 'Close Messages' : 'Messages';
    return (
        <>
            <Button content={btnContent} onClick={openPortal} />
            <Portal
                trapFocus={{
                    isClickableOutsideFocusTrap: false,
                    focusTriggerOnOutsideClick: false,
                    elementToFocusOnDismiss: undefined,
                    forceFocusInsideTrapOnOutsideFocus: false,
                    ignoreExternalFocusing: false,
                    disableFirstFocus: false,
                    firstFocusableSelector: undefined,
                }}
                open={open}
                content={
                    <div
                        style={{
                            backgroundColor: '#fff',
                            position: 'fixed',
                            left: '35%',
                            top: '25%',
                            zIndex: 1000,
                            padding: '15px',
                            boxShadow: 'rgb(187, 187, 187) 0px 2px 8px',
                            border: '1px solid rgba(34,36,38,.15)',
                            borderRadius: '5px',
                        }}
                    >
                        {data.messageCourses.map((course) => {
                            return (
                                <Box key={course.id}>
                                    <Text content={course.courseName} />
                                    <Box>
                                        <TextArea
                                            value={
                                                course.latestMessage?.message
                                            }
                                        />
                                    </Box>
                                </Box>
                            );
                        })}
                        <Button content="Do nothing" />
                        <Button content="Close popup" onClick={closePortal} />
                    </div>
                }
            />
        </>
    );
};

export default MessagePortal;
