import React from 'react';
class TermsOfUse extends React.Component {
  render() {
    return (
      <div>
        <h1>Terms of Use</h1>
      </div>
    );
  }
}

export default TermsOfUse;
