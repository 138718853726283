import React, { useState } from 'react';
import ReactCalendar from 'react-calendar';
import { formatToTimeZone } from 'date-fns-timezone';
import { Grid, Text, Flex } from '@fluentui/react-northstar';
import { format } from 'date-fns';
import { useCalendarQuery } from '../../generated/graphql';
import { Spinner } from '../../components/Spinner';
import '../../assets/css/Calendar.css';

export default function CalendarPage() {
  const day = new Date();
  const { data, loading, error } = useCalendarQuery();
  const [today, setToday] = useState(day);

  if (loading || !data || !data.calendar) {
    return <Spinner />;
  }

  if (error) {
    console.error(error);
  }

  const onClickDay = (e: Date) => {
    setToday(e);
  };

  const calendarMap = data.calendar.reduce<{ [key: string]: string }>(
    (acc, cur) => {
      const [date] = cur.date.split('T');
      // console.log(cur.date, parsed.toISOString());

      acc[date] = cur.abbreviation;
      return acc;
    },
    {}
  );

  const formatDate = (date: Date) =>
    formatToTimeZone(date, 'YYYY-MM-DD', {
      timeZone: 'Asia/Shanghai',
    });

  return (
    <div>
      <Grid
        columns={2}
        content={[
          <ReactCalendar
            // style={{ width: '100%' }}
            value={today}
            onClickDay={onClickDay}
            tileContent={({ date, view }) => {
              const formatted = formatDate(date);

              return view === 'month' ? (
                <p>{calendarMap[formatted]}</p>
              ) : (
                <p></p>
              );
            }}
          />,
          <Flex column hAlign="center" vAlign="center">
            <Text
              size="larger"
              weight="bold"
              content={format(today, 'LLL do')}
            />
            <Text
              size="large"
              content={
                calendarMap[formatDate(today)] ? (
                  <p>{calendarMap[formatDate(today)]} day</p>
                ) : (
                  <p>No school</p>
                )
              }
            />
          </Flex>,
        ]}
      />
    </div>
  );
}
