import { useEffect, useState } from 'react';
import {
    useCyclesQuery,
    useCycleLazyQuery,
    Calendar,
} from '../../generated/graphql';

export const useCycle = () => {
    const { loading: loadingCycles, data: dataCycles } = useCyclesQuery();
    const [
        loadCycle,
        { loading: loadingCycle, data: dataCycle },
    ] = useCycleLazyQuery();

    const [cycles, setCycles] = useState<Partial<Calendar>[]>([]);
    const [cycle, setCycle] = useState<Partial<Calendar>[]>([]);

    useEffect(() => {
        loadCycle();
    }, [loadCycle]);

    useEffect(() => {
        if (dataCycles && dataCycles.cycles) {
            setCycles(dataCycles.cycles);
        }

        if (dataCycle && dataCycle.cycle) {
            setCycle(dataCycle.cycle);
        }
    }, [dataCycles, dataCycle]);

    return {
        loading: loadingCycles || loadingCycle,
        cycles,
        cycle,
    };
};
