import { Box, Text } from '@fluentui/react-northstar';
import React from 'react';
import { formatDate } from '../utils/formatDate';

const CalendarBox = ({ calendar }) => {
    return (
        <Box
            styles={{
                height: 40,
                textAlign: 'center',
            }}
        >
            <Text size="large" weight="bold">
                {calendar.abbreviation}{' '}
            </Text>
            <Text size="small">{formatDate(calendar.date)}</Text>
        </Box>
    );
};

export default CalendarBox;
