import React, { ReactElement, useEffect, useState } from 'react';
import { Text, Flex, Dropdown, Box, Segment } from '@fluentui/react-northstar';
import {
    ChevronStartIcon,
    ChevronEndMediumIcon,
} from '@fluentui/react-icons-northstar';
import {
    Calendar,
    useCoursesLazyQuery,
    useCycleLazyQuery,
} from '../../generated/graphql';
import { useCycle } from '../../utils/hooks/useCycle';
import { Spinner } from '../Spinner';
import { Container } from '../Container';
import { format } from 'date-fns';
import { parseExpression } from '../../utils/parseExpression';
import { formatToTimeZone } from 'date-fns-timezone';
import AssignmentPortal from '../AssignmentPortal';
import CalendarBox from '../CalendarBox';
import FacultyDropdown from '../FacultyDropdown';
import MessagePortal from '../MessagePortal';

function FacultyDashboard({ email }): ReactElement {
    const { cycle, cycles } = useCycle();
    const [currentEmail, setCurrentEmail] = useState(email);
    const [selectedCycle, setSelectedCycle] = useState<Partial<Calendar>[]>([]);

    const [loadCycle, { data: loadedCycle }] = useCycleLazyQuery();

    const [loadCourses, { loading: loadingCourses, data }] =
        useCoursesLazyQuery();

    useEffect(() => {
        if (currentEmail && selectedCycle.length && selectedCycle[0].date) {
            loadCourses({
                variables: {
                    email: currentEmail,
                    cycle: selectedCycle[0].date,
                },
            });
        }
    }, [currentEmail, selectedCycle, loadCourses]);

    useEffect(() => {
        if (loadedCycle) {
            const updatedCycle = loadedCycle.cycle;
            return setSelectedCycle(updatedCycle);
        }

        if (cycle) {
            return setSelectedCycle(cycle);
        }
    }, [cycle, loadedCycle]);

    if (loadingCourses || !data || !cycles) {
        return <Spinner />;
    }

    const formatDate = (date: string) => format(new Date(date), 'EEEE, MMM do');
    const current = formatDate(selectedCycle[0].date);
    const grid = formatGrid(data.courses, selectedCycle);

    const handleCycleChange = (a, b) => {
        const idx = b.items.findIndex((i) => i === b.value);
        loadCycle({
            variables: { date: cycles[idx].date },
        });
    };

    return (
        <Container>
            <Segment
                color={'Brand'}
                content={
                    <Box
                        styles={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(3, 1fr)',
                            justifyContent: 'space-around',
                        }}
                    >
                        <FacultyDropdown
                            email={currentEmail}
                            onChange={setCurrentEmail}
                        />

                        <Flex vAlign="center" gap="gap.small">
                            <ChevronStartIcon />

                            <Dropdown
                                items={cycles.map((c) => formatDate(c.date))}
                                value={current}
                                onChange={handleCycleChange}
                            />

                            <ChevronEndMediumIcon />
                        </Flex>

                        <Box styles={{ justifySelf: 'end' }}>
                            <MessagePortal email={currentEmail} />
                        </Box>
                    </Box>
                }
                inverted
            />

            <Box
                styles={{
                    display: 'grid',
                    gridTemplateColumns: '100px repeat(6, 300px)',
                    gridTemplateRows: `20px repeat(${grid.length - 1}, 100px)`,
                    gridGap: '4px',
                    height: 'calc(100vh - 64px)',
                    overflow: 'scroll',
                }}
            >
                {data.courses.length === 0 ? (
                    <Box>
                        <Box>
                            <p>
                                No courses! Go find someone who is teaching and
                                help them out!
                            </p>
                        </Box>
                    </Box>
                ) : (
                    grid.map((row: any, ind: number) => {
                        return row.map((cell, cellInd) => {
                            if (cellInd === 0) {
                                return (
                                    <Box
                                        styles={{ alignSelf: 'center' }}
                                        key={`${ind}/${cellInd}`}
                                    >
                                        <Text weight="bold">{cell}</Text>
                                    </Box>
                                );
                            }

                            if (cell.__typename === 'Calendar') {
                                return (
                                    <CalendarBox
                                        key={`${ind}/${cellInd}`}
                                        calendar={cell}
                                    />
                                );
                            }

                            if (cell.__typename === 'Assignment') {
                                return (
                                    <AssignmentPortal
                                        key={`${ind}/${cellInd}`}
                                        assignment={cell}
                                    />
                                );
                            }

                            return <Box key={`${ind}/${cellInd}`}></Box>;
                        });
                    })
                )}
            </Box>
        </Container>
    );
}

export default FacultyDashboard;

function formatGrid(courses: any[], cycle: any[]) {
    const headerRow = ['Course', ...cycle];

    const courseRows = courses.map((course) => {
        const row = Array(7).fill(null);
        const expression = parseExpression(course.expression);

        row[0] = course.courseName;
        for (let i = 1; i < headerRow.length; i++) {
            const abbreviation = headerRow[i].abbreviation;
            if (expression.includes(abbreviation)) {
                let [assignment] = course.assignments.filter(
                    (ass: any) => ass.day === abbreviation
                );

                if (!assignment) {
                    // const date = new Date(cycle[0].date);
                    const formattedDate = formatToTimeZone(
                        cycle[0].date,
                        'MM-DD',
                        {
                            timeZone: 'Asia/Shanghai',
                        }
                    );

                    assignment = {
                        __typename: 'Assignment',
                        fileName: `${formattedDate}-${course.school}`,
                        school: course.school,
                        courseIdentifier: course.courseIdentifier,
                        cycle: cycle[0].date,
                        day: abbreviation,
                        assignment: '',
                        live: false,
                        assessment: false,
                    };
                }

                row[i] = assignment;
                //set assignment to the cell
            } else {
                row[i] = '';
            }
        }

        return row;
    });

    return [headerRow, ...courseRows];
}
/*
if (expression.includes(row.abbreviation)) {
    let [assignment] = course.assignments.filter(
        (ass: any) => ass.day === row.abbreviation
    );

    if (!assignment) {
        const date = new Date(state.cycle[0].date);
        const formattedDate = formatToTimeZone(state.cycle[0].date, 'MM-DD', {
            timeZone: 'Asia/Shanghai',
        });

        assignment = {
            fileName: `${formattedDate}-${course.school}`,
            school: course.school,
            courseIdentifier: course.courseIdentifier,
            cycle: state.cycle[0].date,
            day: row.abbreviation,
            assignment: '',
            live: false,
            assessment: false,
        };
    }

    //set assignment to the cell
}
*/
