import {
    Avatar,
    Text,
    Box,
    Card,
    Flex,
    Header,
} from '@fluentui/react-northstar';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import panorama from '../../assets/imgs/keystone-panorama.jpg';
import SearchInput from '../../components/SearchInput';

const Home: React.FC = () => {
    const [searchResults, setSearchResults] = useState<any>([]);

    return (
        <div>
            <Box>
                <img src={panorama} alt="keystone panorama" />
                <Header
                    as="h1"
                    styles={{
                        position: 'absolute',
                        top: '1rem',
                        backgroundColor: 'white',
                        transform: 'skewY(-5deg)',
                    }}
                >
                    Keystone Portal
                </Header>
            </Box>

            <Box>
                <SearchInput setResults={setSearchResults} />
            </Box>

            <Flex gap="gap.small">
                {searchResults.map((result: any) => {
                    return (
                        <Link
                            to={`/landing-pages/student/${
                                result.email.split('@')[0]
                            }`}
                        >
                            <Card
                                styles={{ overflow: 'none' }}
                                aria-roledescription="student avatar"
                            >
                                <Card.Header fitted>
                                    <Flex gap="gap.small">
                                        <Avatar
                                            // label={result.lastFirst}
                                            name={result.lastFirst}
                                        />
                                        <Flex column>
                                            <Text
                                                content={result.lastFirst}
                                                weight="bold"
                                            />
                                            <Text
                                                content={result.email}
                                                size="small"
                                            />
                                        </Flex>
                                    </Flex>
                                </Card.Header>
                            </Card>
                        </Link>
                    );
                })}
            </Flex>
        </div>
    );
};

export default Home;
