import { SCHEDULE } from './constants';
export const formatPeriod = (period: string) => {
    const [start, end] = period.split('-');
    let formatted = '';

    if (start) {
        formatted += SCHEDULE[start].start;
    }

    if (end) {
        formatted += ' - ' + SCHEDULE[end].end;
    }

    return formatted;
};
