import React, { ReactElement, useState } from 'react';
import {
    Text,
    Header,
    Flex,
    Segment,
    Box,
    Button,
} from '@fluentui/react-northstar';
import Markdown from 'markdown-to-jsx';
import { Container } from '../Container';
import { Course, Calendar } from '../../generated/graphql';
import { useStudentSchedule } from '../../utils/hooks/useStudentSchedule';
import { Spinner } from '../Spinner';
import { formatDate } from '../../utils/formatDate';
import { isToday } from 'date-fns';
import { CycleToggle } from '../CycleToggle';
import { formatPeriod } from '../../utils/formatPeriod';

function StudentDashboard({ email }): ReactElement {
    const [selectedCycle, setSelectedCycle] = useState<Partial<Calendar>[]>([]);
    const { data, loading } = useStudentSchedule(email, selectedCycle);

    if (!data || loading) {
        return <Spinner />;
    }

    return (
        <Container>
            <Segment
                styles={{ position: 'fixed', width: '100vw' }}
                color={'blue'}
                inverted
                content={
                    <Flex gap="gap.large" vAlign="center">
                        <Header
                            color="white"
                            content={data.student.lastFirst}
                            styles={{
                                overflow: 'hidden',
                            }}
                        />

                        <Button content="Messages" color="brand" />

                        <Flex vAlign="center">
                            <CycleToggle setSelectedCycle={setSelectedCycle} />
                        </Flex>
                    </Flex>
                }
            />

            <Box styles={{ paddingTop: '110px' }}>
                {selectedCycle.map((day, ind) => {
                    const courses = data.student.schedule
                        .reduce<ExpandedCourse[]>(
                            (acc, course) => reduceCourse(acc, course, day),
                            []
                        )
                        .sort((a, b) => a.startPeriod - b.startPeriod);

                    return (
                        <Flex
                            key={day.abbreviation}
                            gap="gap.medium"
                            color="brand"
                        >
                            <Segment
                                color={ind % 2 ? 'blue' : 'green'}
                                inverted={isToday(new Date(day.date))}
                                style={{ width: '100%' }}
                            >
                                <Text
                                    size="large"
                                    content={
                                        day.abbreviation +
                                        ' day on ' +
                                        formatDate(day.date)
                                    }
                                />
                                <Flex column>
                                    {courses.map((course, ind) => {
                                        return (
                                            <Box key={`${course.id}-${ind}`}>
                                                <Text
                                                    weight="bold"
                                                    content={
                                                        formatPeriod(
                                                            '' + course.period
                                                        ) +
                                                        ' - ' +
                                                        course.courseName
                                                    }
                                                />
                                                {course.assignments &&
                                                course.assignments.length ? (
                                                    course.assignments.map(
                                                        (
                                                            { assignment }: any,
                                                            assInd
                                                        ) => (
                                                            <Markdown
                                                                key={`${course.id}-${ind}-${assInd}`}
                                                            >
                                                                {assignment}
                                                            </Markdown>
                                                        )
                                                    )
                                                ) : (
                                                    <Box>
                                                        <Text content="No assignment yet..." />
                                                    </Box>
                                                )}
                                            </Box>
                                        );
                                    })}
                                </Flex>
                            </Segment>
                        </Flex>
                    );
                })}
            </Box>
        </Container>
    );
}

export default StudentDashboard;

interface ExpandedCourse extends Course {
    period: number;
    startPeriod: number;
}

function reduceCourse(
    acc: ExpandedCourse[],
    course: any,
    date: Partial<Calendar>
) {
    if (!course.expression.includes(date.abbreviation)) {
        return acc;
    }
    const newSchedule: any = {};

    //filter assignments
    newSchedule.assignments = course.assignments
        ? course.assignments.filter((assignment: any) => {
              return assignment.day === date.abbreviation;
          })
        : [];

    const [period] = course.expression
        .split(' ')
        .filter((expression: string) => expression.includes(date.abbreviation!))
        .map((expression: string) => expression.split('(')[0]);

    const [startPeriod] = period.split('-');

    if (+startPeriod >= 10) {
        return acc;
    }

    newSchedule.period = period;
    newSchedule.startPeriod = startPeriod;

    acc.push({ ...course, ...newSchedule });
    return acc;
}
