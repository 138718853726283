import React, { useEffect } from 'react';
import { Input } from '@fluentui/react-northstar';
import { SearchIcon } from '@fluentui/react-icons-northstar';
import { useSearchByNameLazyQuery } from '../generated/graphql';

interface Props {
    setResults: (data: any[]) => void;
}

const SearchInput: React.FC<Props> = ({ setResults }) => {
    const [
        searchByName,
        { data, loading: searching },
    ] = useSearchByNameLazyQuery();

    useEffect(() => {
        if (data) {
            const results = data?.searchByName;
            setResults(results);
        }
    }, [data, setResults]);

    const search = (e) => {
        if (!searching) {
            searchByName({ variables: { lastFirst: e.target.value } });
        }
    };

    return (
        <Input
            icon={<SearchIcon />}
            placeholder="Search by student name..."
            iconPosition="start"
            onChange={search}
            fluid
        />
    );
};

export default SearchInput;
