import { InMemoryCache, ApolloClient } from '@apollo/client';

const cache = new InMemoryCache();

const uri =
  process.env.NODE_ENV === 'production'
    ? 'https://api.keystone.academy'
    : 'http://localhost:4000';

export const client = new ApolloClient({
  uri,
  cache,
  headers: {
    'X-Teams': 'true',
  },
});
