import React from 'react';

class Privacy extends React.Component {
  render() {
    return (
      <div>
        <h1>Privacy Statement</h1>
      </div>
    );
  }
}

export default Privacy;
