import React, { useEffect, useState } from 'react';
import { getContext } from '@microsoft/teams-js';
import FacultyDashboard from '../components/Dashboards/FacultyDashboard';
import StudentDashboard from '../components/Dashboards/StudentDashboard';
import { Spinner } from '../components/Spinner';

interface IUser {
    email: string | undefined;
    type: string | undefined;
}

const devUser: IUser = {
    email: 'sandra.chow@keystoneacademy.cn',
    type: 'Teacher',
};

const Dashboard = () => {
    const [user, setUser] = useState<IUser>({
        email: '',
        type: '',
    });

    useEffect(() => {
        getContext(async (ctx) => {
            const { userPrincipalName, userLicenseType } = ctx;
            if (process.env.NODE_ENV === 'development') {
                setUser(devUser);
            } else {
                setUser({
                    email: userPrincipalName,
                    type: userLicenseType,
                });
            }
        });
    }, []);

    if (!user.type && !user.email) {
        return <Spinner />;
    }

    if (user.type === 'Teacher') {
        return <FacultyDashboard email={user.email} />;
    } else if (user.type === 'Student') {
        return <StudentDashboard email={user.email} />;
    } else {
        return (
            <div>
                <h1>Missing User</h1>
            </div>
        );
    }
};

export default Dashboard;
