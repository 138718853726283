import { Loader } from '@fluentui/react-northstar';
import React from 'react';
import { Container } from './Container';

export const Spinner = ({ label = 'Loading Page' }) => {
  return (
    <Container>
      <Loader size="largest" label={label} labelPosition="below" />
    </Container>
  );
};
