import React from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Privacy from './pages/Privacy';
import TermsOfUse from './pages/TermsOfUse';
import { ApolloProvider } from '@apollo/client';
import { client } from './apollo/apolloClient';
import CalendarPage from './pages/CalendarPage';
import { Provider, teamsTheme } from '@fluentui/react-northstar';
import Dashboard from './pages/Dashboard';
import Home from './pages/Home';

const App: React.FC = () => {
    // Initialize the Microsoft Teams SDK
    microsoftTeams.initialize(window as any);

    //TODO bring this back into play and authenticate with server
    // const authTokenRequest = {
    //     successCallback: function (result) {
    //         console.log('Success: ' + result);
    //     },
    //     failureCallback: function (error) {
    //         console.log('Failure: ' + error);
    //     },
    // };

    // microsoftTeams.authentication.getAuthToken(authTokenRequest);

    // Display the app home page hosted in Teams
    return (
        <Provider theme={teamsTheme}>
            <ApolloProvider client={client}>
                <Router>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/privacy" component={Privacy} />
                    <Route exact path="/termsofuse" component={TermsOfUse} />
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route exact path="/calendar" component={CalendarPage} />
                </Router>
            </ApolloProvider>
        </Provider>
    );
};

export default App;
