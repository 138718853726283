import { useCycle } from './useCycle';
import { Calendar, useStudentScheduleLazyQuery } from '../../generated/graphql';
import { useEffect } from 'react';

export const useStudentSchedule = (
    email: string,
    cycle: Partial<Calendar>[]
) => {
    // console.log(cycleDate);
    const { cycle: initialCycle } = useCycle();

    const [
        loadStudentSchedule,
        { loading, data, error },
    ] = useStudentScheduleLazyQuery();

    useEffect(() => {
        if (cycle.length && cycle[0].date && email) {
            // const theDate = cycleDate ? cycleDate : cycle[0].date;
            loadStudentSchedule({
                variables: {
                    email,
                    cycle: cycle[0].date,
                },
            });
        }

        if (initialCycle.length && email) {
            loadStudentSchedule({
                variables: {
                    email,
                    cycle: initialCycle[0].date,
                },
            });
        }
    }, [cycle, initialCycle, email, loadStudentSchedule]);

    return {
        loading,
        data,
        cycle,
        error,
    };
};
