import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

/**
 * Each assignment is associated with a course.
 * Many assignments can be created for each course.
 */
export type Assignment = {
  __typename?: 'Assignment';
  /** example: {fileName}_{courseIdentifier}_{day}} */
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  fileName: Scalars['String'];
  courseIdentifier: Scalars['String'];
  cycle: Scalars['DateTime'];
  day: Scalars['String'];
  assignment: Scalars['String'];
  live: Scalars['Boolean'];
  assessment: Scalars['Boolean'];
  view?: Maybe<Scalars['Boolean']>;
  outlookTaskId?: Maybe<Scalars['String']>;
};


/** School calendar dates */
export type Calendar = {
  __typename?: 'Calendar';
  id: Scalars['ID'];
  schoolYear: Scalars['String'];
  /** date stamp of the date in ISO notation */
  date: Scalars['DateTime'];
  abbreviation: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  dismissal?: Maybe<Scalars['String']>;
  inSession?: Maybe<Scalars['Float']>;
  /** true/false early dismissal day */
  isEarlyDismissal: Scalars['Boolean'];
};

/**
 * This is a list of all the faculty at Keystone.
 * These records connect with courses.
 * Faculty entities connect with courses.
 * Each Course can have many teachers.
 */
export type Faculty = {
  __typename?: 'Faculty';
  id: Scalars['ID'];
  teacherNumber: Scalars['String'];
  lastFirst: Scalars['String'];
  email: Scalars['String'];
  courses: Array<Course>;
  department: Department;
};

/** Either STUDENT or FACULTY */
export enum Department {
  English = 'ENGLISH',
  Math = 'MATH',
  Humanities = 'HUMANITIES',
  Drama = 'DRAMA',
  Music = 'MUSIC',
  Chinese = 'CHINESE'
}

/**
 * This is a list of all the students at Keystone.
 * These records connect with courses.
 * Every student has many courses.
 * Each Course can have many students.
 */
export type Student = {
  __typename?: 'Student';
  id: Scalars['ID'];
  studentId: Scalars['String'];
  lastFirst: Scalars['String'];
  email: Scalars['String'];
  schedule: Array<Course>;
  house: House;
  advisory?: Maybe<Course>;
};

/** Keystone House */
export enum House {
  Wood = 'WOOD',
  Water = 'WATER',
  Fire = 'FIRE',
  Metal = 'METAL',
  Earth = 'EARTH'
}

/**
 * Each course is connected with both student and faculty records.
 * The expression field defines when the times meet.
 * Assignments are a One-to-Many relationship with each course.
 */
export type Course = {
  __typename?: 'Course';
  id: Scalars['ID'];
  courseName: Scalars['String'];
  courseNumber: Scalars['String'];
  sectionNumber: Scalars['String'];
  expression: Scalars['String'];
  term: Scalars['Float'];
  room?: Maybe<Scalars['String']>;
  /** email address of instructor */
  email: Scalars['String'];
  /** school id */
  schoolId?: Maybe<Scalars['String']>;
  /** teacher description */
  teacherDescr?: Maybe<Scalars['String']>;
  /** determine level from school id */
  school: Scalars['String'];
  gradeLevel?: Maybe<Scalars['Float']>;
  courseIdentifier: Scalars['String'];
  students: Array<Student>;
  instructors: Array<Faculty>;
  assignments?: Maybe<Array<Assignment>>;
  latestMessage?: Maybe<CourseMessage>;
};


/**
 * Each course is connected with both student and faculty records.
 * The expression field defines when the times meet.
 * Assignments are a One-to-Many relationship with each course.
 */
export type CourseAssignmentsArgs = {
  cycle?: Maybe<Scalars['DateTime']>;
};

/** The user table defines users that have authenticated with the Microsoft Graph servers. */
export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  displayName: Scalars['String'];
  email: Scalars['String'];
  jobTitle?: Maybe<Scalars['String']>;
  officeLocation?: Maybe<Scalars['String']>;
  studentFaculty: StudentFacultyEnum;
  permission: Permission;
  messages: Array<CourseMessage>;
};

/** Either STUDENT or FACULTY */
export enum StudentFacultyEnum {
  Student = 'STUDENT',
  Faculty = 'FACULTY'
}

/** Defined permissions for administration */
export enum Permission {
  User = 'USER',
  Moderator = 'MODERATOR',
  Admin = 'ADMIN',
  Super = 'SUPER'
}

/** Each course can have a message */
export type CourseMessage = {
  __typename?: 'CourseMessage';
  /** example: {courseIdentifier}_{cycle}} */
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  message: Scalars['String'];
};

export type ExcelResponse = {
  __typename?: 'ExcelResponse';
  webUrl: Scalars['String'];
  success?: Maybe<Array<Scalars['String']>>;
  failure?: Maybe<Array<Scalars['String']>>;
};

export type ImportStats = {
  __typename?: 'ImportStats';
  days: Scalars['Float'];
  courses: Scalars['Float'];
  faculty: Scalars['Float'];
  students: Scalars['Float'];
  assignments: Scalars['Float'];
};

export type Notebook = {
  __typename?: 'Notebook';
  id: Scalars['String'];
  self: Scalars['String'];
  createdDateTime: Scalars['String'];
  displayName: Scalars['String'];
  lastModifiedDateTime: Scalars['String'];
  isDefault: Scalars['Boolean'];
  userRole: Scalars['String'];
  isShared: Scalars['Boolean'];
  sectionsUrl: Scalars['String'];
  sectionGroupsUrl: Scalars['String'];
};

export type Section = {
  __typename?: 'Section';
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
  pagesUrl: Scalars['String'];
  displayName: Scalars['String'];
};

export type Url = {
  __typename?: 'Url';
  href: Scalars['String'];
};

export type Link = {
  __typename?: 'Link';
  oneNoteClientUrl: Url;
  oneNoteWebUrl: Url;
};

export type Team = {
  __typename?: 'Team';
  id: Scalars['String'];
  createdDateTime?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  description: Scalars['String'];
  visibility: Scalars['Boolean'];
  webUrl: Scalars['String'];
  channels: Array<Channel>;
};

export type Channel = {
  __typename?: 'Channel';
  id: Scalars['String'];
  displayName: Scalars['String'];
  description: Scalars['String'];
  email: Scalars['String'];
  webUrl: Scalars['String'];
};

export type ItemBody = {
  __typename?: 'ItemBody';
  content: Scalars['String'];
  contentType: Scalars['String'];
};

export type FromUser = {
  __typename?: 'FromUser';
  id: Scalars['String'];
  displayName: Scalars['String'];
  userIdentityType: Scalars['String'];
};

export type From = {
  __typename?: 'From';
  application?: Maybe<Scalars['String']>;
  device?: Maybe<Scalars['String']>;
  conversation?: Maybe<Scalars['String']>;
  user: FromUser;
};

export type Attachment = {
  __typename?: 'Attachment';
  id: Scalars['String'];
  contentType: Scalars['String'];
  contentUrl: Scalars['String'];
  content: Scalars['String'];
  name: Scalars['String'];
  thumbnailUrl: Scalars['String'];
};

export type Message = {
  __typename?: 'Message';
  id: Scalars['String'];
  replyToId: Scalars['String'];
  from: From;
  etag: Scalars['String'];
  messageType: Scalars['String'];
  createdDateTime?: Maybe<Scalars['String']>;
  lastModifiedDateTime: Scalars['DateTime'];
  deletedDateTime: Scalars['DateTime'];
  subject?: Maybe<Scalars['String']>;
  body: ItemBody;
  summary: Scalars['String'];
  mentions: Array<From>;
  attachments: Array<Attachment>;
  importance: Scalars['String'];
  policyViolation: Scalars['String'];
  reactions: Scalars['String'];
  locale: Scalars['String'];
  deleted: Scalars['Boolean'];
  replies?: Maybe<Array<Message>>;
};

export type DateTimeTimeZone = {
  __typename?: 'DateTimeTimeZone';
  dateTime: Scalars['String'];
  timeZone: Scalars['String'];
};

export type OutlookTask = {
  __typename?: 'OutlookTask';
  assignedTo: Scalars['String'];
  body: ItemBody;
  categories: Array<Scalars['String']>;
  changeKey: Scalars['String'];
  completedDateTime: DateTimeTimeZone;
  createdDateTime: Scalars['DateTime'];
  dueDateTime: DateTimeTimeZone;
  hasAttachments: Scalars['Boolean'];
  id: Scalars['String'];
  importance: Scalars['String'];
  isReminderOn: Scalars['Boolean'];
  lastModifiedDateTime: Scalars['DateTime'];
  owner: Scalars['String'];
  parentFolderId: Scalars['String'];
  reminderDateTime: DateTimeTimeZone;
  sensitivity: Scalars['String'];
  startDateTime: DateTimeTimeZone;
  status: Scalars['String'];
  subject: Scalars['String'];
};

export type SaveResponse = {
  __typename?: 'SaveResponse';
  message: Scalars['String'];
};

/** Sharepoint site definition with only ID and NAME fields */
export type SharepointSite = {
  __typename?: 'SharepointSite';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SharepointList = {
  __typename?: 'SharepointList';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SharepointListFields = {
  __typename?: 'SharepointListFields';
  id: Scalars['String'];
  Title: Scalars['String'];
  AuthorLookupId: Scalars['Float'];
  EditorLookupId: Scalars['Float'];
  TotalQuantity: Scalars['Float'];
};

export type CalendarInput = {
  /** date stamp of the date in ISO notation */
  date: Scalars['DateTime'];
  abbreviation: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  dismissal?: Maybe<Scalars['String']>;
};

export type Body = {
  content?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
};

export type EventDateTime = {
  dateTime?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
};

export type EventLocation = {
  displayName: Scalars['String'];
};

export type OutlookEventsInput = {
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Body>;
  start?: Maybe<EventDateTime>;
  end?: Maybe<EventDateTime>;
  isAllDay?: Maybe<Scalars['Boolean']>;
  showAs?: Maybe<Scalars['String']>;
  isReminderOn?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Array<Scalars['String']>>;
  location?: Maybe<EventLocation>;
};

export type Values = {
  day: Scalars['String'];
  assignment: Scalars['String'];
  live?: Maybe<Scalars['Boolean']>;
  assessment?: Maybe<Scalars['Boolean']>;
  view?: Maybe<Scalars['Boolean']>;
};

export type UpdateAssignmentInput = {
  fileName: Scalars['String'];
  courseIdentifier: Scalars['String'];
  values: Array<Values>;
};

export type ChannelInput = {
  id: Scalars['String'];
  displayName: Scalars['String'];
};

export type AssignmentInput = {
  subject: Scalars['String'];
  assignment: Scalars['String'];
  start: Scalars['String'];
  end: Scalars['String'];
  categories?: Maybe<Array<Scalars['String']>>;
};

export type DateTimeTimeZoneInput = {
  dateTime: Scalars['String'];
  timeZone: Scalars['String'];
};

export type BodyInput = {
  content: Scalars['String'];
  contentType?: Maybe<Scalars['String']>;
};

export type OutlookTaskInput = {
  subject: Scalars['String'];
  categories?: Maybe<Array<Scalars['String']>>;
  isReminderOn?: Maybe<Scalars['Boolean']>;
  dueDateTime?: Maybe<DateTimeTimeZoneInput>;
  body: BodyInput;
};

export type UserInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  officeLocation?: Maybe<Scalars['String']>;
  studentFaculty?: Maybe<StudentFacultyEnum>;
  schoolId?: Maybe<Scalars['String']>;
  permission?: Maybe<Permission>;
};

export type Query = {
  __typename?: 'Query';
  counts: ImportStats;
  calendar?: Maybe<Array<Calendar>>;
  remainingCycles: Array<Calendar>;
  cycles: Array<Calendar>;
  cycle: Array<Calendar>;
  allFileNames: Array<Scalars['String']>;
  courses: Array<Course>;
  myCourses: Array<Course>;
  /** Find a single course by email, course details, studentname */
  course?: Maybe<Course>;
  messageCourses: Array<Course>;
  coursesThisCycle: Array<Course>;
  studentAssignments: Array<Assignment>;
  notebooks: Array<Notebook>;
  sections: Array<Section>;
  outlookTasks: Scalars['String'];
  sites: Array<SharepointSite>;
  lists: Array<SharepointList>;
  list: Array<SharepointListFields>;
  faculty: Array<Faculty>;
  instructor: Faculty;
  searchByName: Array<Student>;
  students: Array<Student>;
  student: Student;
  /** get the messages for student schedule KEEP */
  studentMessages: Array<Course>;
  tasks: Scalars['String'];
  buckets: Scalars['String'];
  teams: Array<Team>;
  me?: Maybe<User>;
  authUrl: Scalars['String'];
  teamsValidate: User;
  users: Array<User>;
  user: User;
};


export type QueryCycleArgs = {
  date?: Maybe<Scalars['DateTime']>;
};


export type QueryCoursesArgs = {
  includeCPT?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
};


export type QueryMyCoursesArgs = {
  cycle?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};


export type QueryCourseArgs = {
  sectionNumber: Scalars['String'];
  courseNumber: Scalars['String'];
};


export type QueryMessageCoursesArgs = {
  email: Scalars['String'];
};


export type QueryCoursesThisCycleArgs = {
  cycle: Scalars['DateTime'];
};


export type QueryStudentAssignmentsArgs = {
  courseIdentifier: Scalars['String'];
  fileName: Scalars['String'];
};


export type QuerySectionsArgs = {
  notebookId: Scalars['String'];
};


export type QuerySitesArgs = {
  search?: Maybe<Scalars['String']>;
};


export type QueryListsArgs = {
  sharepointId: Scalars['String'];
};


export type QueryListArgs = {
  listId: Scalars['String'];
  sharepointId: Scalars['String'];
};


export type QueryInstructorArgs = {
  email: Scalars['String'];
};


export type QuerySearchByNameArgs = {
  lastFirst: Scalars['String'];
};


export type QueryStudentArgs = {
  email: Scalars['String'];
};


export type QueryStudentMessagesArgs = {
  email: Scalars['String'];
};


export type QueryTeamsValidateArgs = {
  type: Scalars['String'];
  email: Scalars['String'];
};


export type QueryUserArgs = {
  username: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  syncExcel: Scalars['String'];
  syncExcelByCourse: Scalars['String'];
  createCalendar: Array<Calendar>;
  createEvents: Scalars['String'];
  createDays: Scalars['String'];
  deleteEvents: Scalars['String'];
  updateAssignments: Array<SaveResponse>;
  /** add messages to the course */
  addMessage: Course;
  importStudents: Array<Student>;
  importFaculty: Array<Faculty>;
  importCourses: Array<Course>;
  importSchedules: Array<Course>;
  importCalendar: Array<Calendar>;
  importInitialData: Scalars['Boolean'];
  createNotebook?: Maybe<Scalars['String']>;
  createOutlookTask: Scalars['String'];
  searchStudents: Student;
  searchCourses: Course;
  createTask: Scalars['String'];
  authorize?: Maybe<User>;
  logout: Scalars['String'];
  updateUser: User;
};


export type MutationSyncExcelArgs = {
  fileName: Scalars['String'];
};


export type MutationSyncExcelByCourseArgs = {
  courseIdentifier: Scalars['String'];
  fileName: Scalars['String'];
};


export type MutationCreateCalendarArgs = {
  data: Array<CalendarInput>;
};


export type MutationCreateEventsArgs = {
  events: Array<OutlookEventsInput>;
};


export type MutationCreateDaysArgs = {
  events: Array<OutlookEventsInput>;
};


export type MutationUpdateAssignmentsArgs = {
  data: Array<UpdateAssignmentInput>;
};


export type MutationAddMessageArgs = {
  cycle?: Maybe<Scalars['DateTime']>;
  message: Scalars['String'];
  userId: Scalars['Float'];
  courseId: Scalars['Float'];
};


export type MutationCreateNotebookArgs = {
  sectionName?: Maybe<Scalars['String']>;
  channels: Array<ChannelInput>;
  teamId: Scalars['String'];
};


export type MutationCreateOutlookTaskArgs = {
  assignment: AssignmentInput;
};


export type MutationSearchStudentsArgs = {
  lastFirst: Scalars['String'];
};


export type MutationSearchCoursesArgs = {
  courseName: Scalars['String'];
};


export type MutationCreateTaskArgs = {
  task: Scalars['String'];
};


export type MutationAuthorizeArgs = {
  code: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  userInput: UserInput;
  userId: Scalars['Float'];
};

export type AddMessageMutationVariables = Exact<{
  courseId: Scalars['Float'];
  userId: Scalars['Float'];
  message: Scalars['String'];
  cycle: Scalars['DateTime'];
}>;


export type AddMessageMutation = (
  { __typename?: 'Mutation' }
  & { addMessage: (
    { __typename?: 'Course' }
    & Pick<Course, 'id'>
    & { latestMessage?: Maybe<(
      { __typename?: 'CourseMessage' }
      & Pick<CourseMessage, 'message'>
    )> }
  ) }
);

export type UpdateAssignmentsMutationVariables = Exact<{
  data: Array<UpdateAssignmentInput>;
}>;


export type UpdateAssignmentsMutation = (
  { __typename?: 'Mutation' }
  & { updateAssignments: Array<(
    { __typename?: 'SaveResponse' }
    & Pick<SaveResponse, 'message'>
  )> }
);

export type CalendarQueryVariables = Exact<{ [key: string]: never; }>;


export type CalendarQuery = (
  { __typename?: 'Query' }
  & { calendar?: Maybe<Array<(
    { __typename?: 'Calendar' }
    & Pick<Calendar, 'date' | 'abbreviation'>
  )>> }
);

export type CourseScheduleQueryVariables = Exact<{
  courseNumber: Scalars['String'];
  sectionNumber: Scalars['String'];
}>;


export type CourseScheduleQuery = (
  { __typename?: 'Query' }
  & { course?: Maybe<(
    { __typename?: 'Course' }
    & Pick<Course, 'expression' | 'courseName'>
    & { instructors: Array<(
      { __typename?: 'Faculty' }
      & Pick<Faculty, 'id' | 'lastFirst'>
    )>, students: Array<(
      { __typename?: 'Student' }
      & Pick<Student, 'id' | 'lastFirst' | 'email'>
    )> }
  )> }
);

export type CoursesQueryVariables = Exact<{
  email: Scalars['String'];
  cycle?: Maybe<Scalars['DateTime']>;
}>;


export type CoursesQuery = (
  { __typename?: 'Query' }
  & { courses: Array<(
    { __typename?: 'Course' }
    & Pick<Course, 'id' | 'courseName' | 'courseNumber' | 'sectionNumber' | 'expression' | 'courseIdentifier' | 'school' | 'term'>
    & { assignments?: Maybe<Array<(
      { __typename?: 'Assignment' }
      & Pick<Assignment, 'fileName' | 'courseIdentifier' | 'day' | 'assignment' | 'live' | 'assessment'>
    )>> }
  )> }
);

export type CycleQueryVariables = Exact<{
  date?: Maybe<Scalars['DateTime']>;
}>;


export type CycleQuery = (
  { __typename?: 'Query' }
  & { cycle: Array<(
    { __typename?: 'Calendar' }
    & Pick<Calendar, 'date' | 'abbreviation'>
  )> }
);

export type CycleByDateQueryVariables = Exact<{
  date?: Maybe<Scalars['DateTime']>;
}>;


export type CycleByDateQuery = (
  { __typename?: 'Query' }
  & { cycle: Array<(
    { __typename?: 'Calendar' }
    & Pick<Calendar, 'date' | 'abbreviation'>
  )> }
);

export type CyclesQueryVariables = Exact<{ [key: string]: never; }>;


export type CyclesQuery = (
  { __typename?: 'Query' }
  & { cycles: Array<(
    { __typename?: 'Calendar' }
    & Pick<Calendar, 'date' | 'abbreviation'>
  )> }
);

export type FacultyQueryVariables = Exact<{ [key: string]: never; }>;


export type FacultyQuery = (
  { __typename?: 'Query' }
  & { faculty: Array<(
    { __typename?: 'Faculty' }
    & Pick<Faculty, 'id' | 'lastFirst' | 'email'>
  )> }
);

export type MessageCoursesQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type MessageCoursesQuery = (
  { __typename?: 'Query' }
  & { messageCourses: Array<(
    { __typename?: 'Course' }
    & Pick<Course, 'id' | 'courseName' | 'courseNumber' | 'sectionNumber'>
    & { latestMessage?: Maybe<(
      { __typename?: 'CourseMessage' }
      & Pick<CourseMessage, 'id' | 'createdAt' | 'message'>
    )> }
  )> }
);

export type SearchByNameQueryVariables = Exact<{
  lastFirst: Scalars['String'];
}>;


export type SearchByNameQuery = (
  { __typename?: 'Query' }
  & { searchByName: Array<(
    { __typename?: 'Student' }
    & Pick<Student, 'email' | 'lastFirst'>
  )> }
);

export type StudentScheduleQueryVariables = Exact<{
  email: Scalars['String'];
  cycle?: Maybe<Scalars['DateTime']>;
}>;


export type StudentScheduleQuery = (
  { __typename?: 'Query' }
  & { student: (
    { __typename?: 'Student' }
    & Pick<Student, 'id' | 'lastFirst'>
    & { schedule: Array<(
      { __typename?: 'Course' }
      & Pick<Course, 'id' | 'courseName' | 'courseNumber' | 'sectionNumber' | 'courseIdentifier' | 'expression'>
      & { instructors: Array<(
        { __typename?: 'Faculty' }
        & Pick<Faculty, 'lastFirst'>
      )>, assignments?: Maybe<Array<(
        { __typename?: 'Assignment' }
        & Pick<Assignment, 'id' | 'day' | 'assignment' | 'live' | 'assessment' | 'outlookTaskId'>
      )>> }
    )> }
  ) }
);

export type TeamsValidateQueryVariables = Exact<{
  email: Scalars['String'];
  type: Scalars['String'];
}>;


export type TeamsValidateQuery = (
  { __typename?: 'Query' }
  & { teamsValidate: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type UserQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName' | 'email' | 'studentFaculty' | 'permission'>
  ) }
);


export const AddMessageDocument = gql`
    mutation AddMessage($courseId: Float!, $userId: Float!, $message: String!, $cycle: DateTime!) {
  addMessage(courseId: $courseId, userId: $userId, message: $message, cycle: $cycle) {
    id
    latestMessage {
      message
    }
  }
}
    `;
export type AddMessageMutationFn = Apollo.MutationFunction<AddMessageMutation, AddMessageMutationVariables>;

/**
 * __useAddMessageMutation__
 *
 * To run a mutation, you first call `useAddMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMessageMutation, { data, loading, error }] = useAddMessageMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      userId: // value for 'userId'
 *      message: // value for 'message'
 *      cycle: // value for 'cycle'
 *   },
 * });
 */
export function useAddMessageMutation(baseOptions?: Apollo.MutationHookOptions<AddMessageMutation, AddMessageMutationVariables>) {
        return Apollo.useMutation<AddMessageMutation, AddMessageMutationVariables>(AddMessageDocument, baseOptions);
      }
export type AddMessageMutationHookResult = ReturnType<typeof useAddMessageMutation>;
export type AddMessageMutationResult = Apollo.MutationResult<AddMessageMutation>;
export type AddMessageMutationOptions = Apollo.BaseMutationOptions<AddMessageMutation, AddMessageMutationVariables>;
export const UpdateAssignmentsDocument = gql`
    mutation UpdateAssignments($data: [UpdateAssignmentInput!]!) {
  updateAssignments(data: $data) {
    message
  }
}
    `;
export type UpdateAssignmentsMutationFn = Apollo.MutationFunction<UpdateAssignmentsMutation, UpdateAssignmentsMutationVariables>;

/**
 * __useUpdateAssignmentsMutation__
 *
 * To run a mutation, you first call `useUpdateAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignmentsMutation, { data, loading, error }] = useUpdateAssignmentsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAssignmentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssignmentsMutation, UpdateAssignmentsMutationVariables>) {
        return Apollo.useMutation<UpdateAssignmentsMutation, UpdateAssignmentsMutationVariables>(UpdateAssignmentsDocument, baseOptions);
      }
export type UpdateAssignmentsMutationHookResult = ReturnType<typeof useUpdateAssignmentsMutation>;
export type UpdateAssignmentsMutationResult = Apollo.MutationResult<UpdateAssignmentsMutation>;
export type UpdateAssignmentsMutationOptions = Apollo.BaseMutationOptions<UpdateAssignmentsMutation, UpdateAssignmentsMutationVariables>;
export const CalendarDocument = gql`
    query Calendar {
  calendar {
    date
    abbreviation
  }
}
    `;

/**
 * __useCalendarQuery__
 *
 * To run a query within a React component, call `useCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarQuery({
 *   variables: {
 *   },
 * });
 */
export function useCalendarQuery(baseOptions?: Apollo.QueryHookOptions<CalendarQuery, CalendarQueryVariables>) {
        return Apollo.useQuery<CalendarQuery, CalendarQueryVariables>(CalendarDocument, baseOptions);
      }
export function useCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarQuery, CalendarQueryVariables>) {
          return Apollo.useLazyQuery<CalendarQuery, CalendarQueryVariables>(CalendarDocument, baseOptions);
        }
export type CalendarQueryHookResult = ReturnType<typeof useCalendarQuery>;
export type CalendarLazyQueryHookResult = ReturnType<typeof useCalendarLazyQuery>;
export type CalendarQueryResult = Apollo.QueryResult<CalendarQuery, CalendarQueryVariables>;
export const CourseScheduleDocument = gql`
    query CourseSchedule($courseNumber: String!, $sectionNumber: String!) {
  course(courseNumber: $courseNumber, sectionNumber: $sectionNumber) {
    expression
    courseName
    instructors {
      id
      lastFirst
    }
    students {
      id
      lastFirst
      email
    }
  }
}
    `;

/**
 * __useCourseScheduleQuery__
 *
 * To run a query within a React component, call `useCourseScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseScheduleQuery({
 *   variables: {
 *      courseNumber: // value for 'courseNumber'
 *      sectionNumber: // value for 'sectionNumber'
 *   },
 * });
 */
export function useCourseScheduleQuery(baseOptions?: Apollo.QueryHookOptions<CourseScheduleQuery, CourseScheduleQueryVariables>) {
        return Apollo.useQuery<CourseScheduleQuery, CourseScheduleQueryVariables>(CourseScheduleDocument, baseOptions);
      }
export function useCourseScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CourseScheduleQuery, CourseScheduleQueryVariables>) {
          return Apollo.useLazyQuery<CourseScheduleQuery, CourseScheduleQueryVariables>(CourseScheduleDocument, baseOptions);
        }
export type CourseScheduleQueryHookResult = ReturnType<typeof useCourseScheduleQuery>;
export type CourseScheduleLazyQueryHookResult = ReturnType<typeof useCourseScheduleLazyQuery>;
export type CourseScheduleQueryResult = Apollo.QueryResult<CourseScheduleQuery, CourseScheduleQueryVariables>;
export const CoursesDocument = gql`
    query Courses($email: String!, $cycle: DateTime) {
  courses(email: $email) {
    id
    courseName
    courseNumber
    sectionNumber
    expression
    courseIdentifier
    school
    term
    assignments(cycle: $cycle) {
      fileName
      courseIdentifier
      day
      assignment
      live
      assessment
    }
  }
}
    `;

/**
 * __useCoursesQuery__
 *
 * To run a query within a React component, call `useCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoursesQuery({
 *   variables: {
 *      email: // value for 'email'
 *      cycle: // value for 'cycle'
 *   },
 * });
 */
export function useCoursesQuery(baseOptions?: Apollo.QueryHookOptions<CoursesQuery, CoursesQueryVariables>) {
        return Apollo.useQuery<CoursesQuery, CoursesQueryVariables>(CoursesDocument, baseOptions);
      }
export function useCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoursesQuery, CoursesQueryVariables>) {
          return Apollo.useLazyQuery<CoursesQuery, CoursesQueryVariables>(CoursesDocument, baseOptions);
        }
export type CoursesQueryHookResult = ReturnType<typeof useCoursesQuery>;
export type CoursesLazyQueryHookResult = ReturnType<typeof useCoursesLazyQuery>;
export type CoursesQueryResult = Apollo.QueryResult<CoursesQuery, CoursesQueryVariables>;
export const CycleDocument = gql`
    query Cycle($date: DateTime) {
  cycle(date: $date) {
    date
    abbreviation
  }
}
    `;

/**
 * __useCycleQuery__
 *
 * To run a query within a React component, call `useCycleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCycleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCycleQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useCycleQuery(baseOptions?: Apollo.QueryHookOptions<CycleQuery, CycleQueryVariables>) {
        return Apollo.useQuery<CycleQuery, CycleQueryVariables>(CycleDocument, baseOptions);
      }
export function useCycleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CycleQuery, CycleQueryVariables>) {
          return Apollo.useLazyQuery<CycleQuery, CycleQueryVariables>(CycleDocument, baseOptions);
        }
export type CycleQueryHookResult = ReturnType<typeof useCycleQuery>;
export type CycleLazyQueryHookResult = ReturnType<typeof useCycleLazyQuery>;
export type CycleQueryResult = Apollo.QueryResult<CycleQuery, CycleQueryVariables>;
export const CycleByDateDocument = gql`
    query CycleByDate($date: DateTime) {
  cycle(date: $date) {
    date
    abbreviation
  }
}
    `;

/**
 * __useCycleByDateQuery__
 *
 * To run a query within a React component, call `useCycleByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCycleByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCycleByDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useCycleByDateQuery(baseOptions?: Apollo.QueryHookOptions<CycleByDateQuery, CycleByDateQueryVariables>) {
        return Apollo.useQuery<CycleByDateQuery, CycleByDateQueryVariables>(CycleByDateDocument, baseOptions);
      }
export function useCycleByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CycleByDateQuery, CycleByDateQueryVariables>) {
          return Apollo.useLazyQuery<CycleByDateQuery, CycleByDateQueryVariables>(CycleByDateDocument, baseOptions);
        }
export type CycleByDateQueryHookResult = ReturnType<typeof useCycleByDateQuery>;
export type CycleByDateLazyQueryHookResult = ReturnType<typeof useCycleByDateLazyQuery>;
export type CycleByDateQueryResult = Apollo.QueryResult<CycleByDateQuery, CycleByDateQueryVariables>;
export const CyclesDocument = gql`
    query Cycles {
  cycles {
    date
    abbreviation
  }
}
    `;

/**
 * __useCyclesQuery__
 *
 * To run a query within a React component, call `useCyclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCyclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCyclesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCyclesQuery(baseOptions?: Apollo.QueryHookOptions<CyclesQuery, CyclesQueryVariables>) {
        return Apollo.useQuery<CyclesQuery, CyclesQueryVariables>(CyclesDocument, baseOptions);
      }
export function useCyclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CyclesQuery, CyclesQueryVariables>) {
          return Apollo.useLazyQuery<CyclesQuery, CyclesQueryVariables>(CyclesDocument, baseOptions);
        }
export type CyclesQueryHookResult = ReturnType<typeof useCyclesQuery>;
export type CyclesLazyQueryHookResult = ReturnType<typeof useCyclesLazyQuery>;
export type CyclesQueryResult = Apollo.QueryResult<CyclesQuery, CyclesQueryVariables>;
export const FacultyDocument = gql`
    query Faculty {
  faculty {
    id
    lastFirst
    email
  }
}
    `;

/**
 * __useFacultyQuery__
 *
 * To run a query within a React component, call `useFacultyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacultyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacultyQuery({
 *   variables: {
 *   },
 * });
 */
export function useFacultyQuery(baseOptions?: Apollo.QueryHookOptions<FacultyQuery, FacultyQueryVariables>) {
        return Apollo.useQuery<FacultyQuery, FacultyQueryVariables>(FacultyDocument, baseOptions);
      }
export function useFacultyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FacultyQuery, FacultyQueryVariables>) {
          return Apollo.useLazyQuery<FacultyQuery, FacultyQueryVariables>(FacultyDocument, baseOptions);
        }
export type FacultyQueryHookResult = ReturnType<typeof useFacultyQuery>;
export type FacultyLazyQueryHookResult = ReturnType<typeof useFacultyLazyQuery>;
export type FacultyQueryResult = Apollo.QueryResult<FacultyQuery, FacultyQueryVariables>;
export const MessageCoursesDocument = gql`
    query MessageCourses($email: String!) {
  messageCourses(email: $email) {
    id
    courseName
    courseNumber
    sectionNumber
    latestMessage {
      id
      createdAt
      message
    }
  }
}
    `;

/**
 * __useMessageCoursesQuery__
 *
 * To run a query within a React component, call `useMessageCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageCoursesQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useMessageCoursesQuery(baseOptions?: Apollo.QueryHookOptions<MessageCoursesQuery, MessageCoursesQueryVariables>) {
        return Apollo.useQuery<MessageCoursesQuery, MessageCoursesQueryVariables>(MessageCoursesDocument, baseOptions);
      }
export function useMessageCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageCoursesQuery, MessageCoursesQueryVariables>) {
          return Apollo.useLazyQuery<MessageCoursesQuery, MessageCoursesQueryVariables>(MessageCoursesDocument, baseOptions);
        }
export type MessageCoursesQueryHookResult = ReturnType<typeof useMessageCoursesQuery>;
export type MessageCoursesLazyQueryHookResult = ReturnType<typeof useMessageCoursesLazyQuery>;
export type MessageCoursesQueryResult = Apollo.QueryResult<MessageCoursesQuery, MessageCoursesQueryVariables>;
export const SearchByNameDocument = gql`
    query SearchByName($lastFirst: String!) {
  searchByName(lastFirst: $lastFirst) {
    email
    lastFirst
  }
}
    `;

/**
 * __useSearchByNameQuery__
 *
 * To run a query within a React component, call `useSearchByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchByNameQuery({
 *   variables: {
 *      lastFirst: // value for 'lastFirst'
 *   },
 * });
 */
export function useSearchByNameQuery(baseOptions?: Apollo.QueryHookOptions<SearchByNameQuery, SearchByNameQueryVariables>) {
        return Apollo.useQuery<SearchByNameQuery, SearchByNameQueryVariables>(SearchByNameDocument, baseOptions);
      }
export function useSearchByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchByNameQuery, SearchByNameQueryVariables>) {
          return Apollo.useLazyQuery<SearchByNameQuery, SearchByNameQueryVariables>(SearchByNameDocument, baseOptions);
        }
export type SearchByNameQueryHookResult = ReturnType<typeof useSearchByNameQuery>;
export type SearchByNameLazyQueryHookResult = ReturnType<typeof useSearchByNameLazyQuery>;
export type SearchByNameQueryResult = Apollo.QueryResult<SearchByNameQuery, SearchByNameQueryVariables>;
export const StudentScheduleDocument = gql`
    query StudentSchedule($email: String!, $cycle: DateTime) {
  student(email: $email) {
    id
    lastFirst
    schedule {
      id
      courseName
      courseNumber
      sectionNumber
      courseIdentifier
      expression
      instructors {
        lastFirst
      }
      assignments(cycle: $cycle) {
        id
        day
        assignment
        live
        assessment
        outlookTaskId
      }
    }
  }
}
    `;

/**
 * __useStudentScheduleQuery__
 *
 * To run a query within a React component, call `useStudentScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentScheduleQuery({
 *   variables: {
 *      email: // value for 'email'
 *      cycle: // value for 'cycle'
 *   },
 * });
 */
export function useStudentScheduleQuery(baseOptions?: Apollo.QueryHookOptions<StudentScheduleQuery, StudentScheduleQueryVariables>) {
        return Apollo.useQuery<StudentScheduleQuery, StudentScheduleQueryVariables>(StudentScheduleDocument, baseOptions);
      }
export function useStudentScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentScheduleQuery, StudentScheduleQueryVariables>) {
          return Apollo.useLazyQuery<StudentScheduleQuery, StudentScheduleQueryVariables>(StudentScheduleDocument, baseOptions);
        }
export type StudentScheduleQueryHookResult = ReturnType<typeof useStudentScheduleQuery>;
export type StudentScheduleLazyQueryHookResult = ReturnType<typeof useStudentScheduleLazyQuery>;
export type StudentScheduleQueryResult = Apollo.QueryResult<StudentScheduleQuery, StudentScheduleQueryVariables>;
export const TeamsValidateDocument = gql`
    query TeamsValidate($email: String!, $type: String!) {
  teamsValidate(email: $email, type: $type) {
    id
  }
}
    `;

/**
 * __useTeamsValidateQuery__
 *
 * To run a query within a React component, call `useTeamsValidateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsValidateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsValidateQuery({
 *   variables: {
 *      email: // value for 'email'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useTeamsValidateQuery(baseOptions?: Apollo.QueryHookOptions<TeamsValidateQuery, TeamsValidateQueryVariables>) {
        return Apollo.useQuery<TeamsValidateQuery, TeamsValidateQueryVariables>(TeamsValidateDocument, baseOptions);
      }
export function useTeamsValidateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamsValidateQuery, TeamsValidateQueryVariables>) {
          return Apollo.useLazyQuery<TeamsValidateQuery, TeamsValidateQueryVariables>(TeamsValidateDocument, baseOptions);
        }
export type TeamsValidateQueryHookResult = ReturnType<typeof useTeamsValidateQuery>;
export type TeamsValidateLazyQueryHookResult = ReturnType<typeof useTeamsValidateLazyQuery>;
export type TeamsValidateQueryResult = Apollo.QueryResult<TeamsValidateQuery, TeamsValidateQueryVariables>;
export const UserDocument = gql`
    query User($username: String!) {
  user(username: $username) {
    id
    displayName
    email
    studentFaculty
    permission
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;