import { Dropdown } from '@fluentui/react-northstar';
import React, { useState } from 'react';
import { useFacultyQuery } from '../generated/graphql';

interface Props {
    email: string;
    onChange: Function;
}

const FacultyDropdown = (props: Props) => {
    const { data, loading } = useFacultyQuery();
    const [value, setValue] = useState({ key: '', header: 'hello' });

    const handleChange = (_, b) => {
        if (b.value) {
            props.onChange(b.value.key);
            setValue(b.value);
        }
    };

    if (!data || loading) {
        return <p>Loading...</p>;
    }

    const items = data.faculty
        .map((f) => ({
            key: f.email,
            header: f.lastFirst,
        }))
        .sort((a, b) => a.header.localeCompare(b.header));

    let initialItem = items.filter((f) => f.key === props.email).pop();

    if (!initialItem) {
        initialItem = {
            key: '',
            header: 'testing',
        };
    }
    console.log('initial item', initialItem);
    return (
        <Dropdown
            search
            clearable
            loading={loading}
            items={items}
            activeSelectedIndex={23}
            defaultSearchQuery={initialItem.header}
            //     value={value}
            onChange={handleChange}
        />
    );
};

export default FacultyDropdown;
